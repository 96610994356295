define('@fortawesome/pro-regular-svg-icons', ['exports'], function (exports) { 'use strict';

  var faBackward = {
    prefix: 'far',
    iconName: 'backward',
    icon: [512, 512, [], "f04a", "M267.5 281.2l192 159.4c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6L267.5 232c-15.3 12.8-15.3 36.4 0 49.2zM464 130.3V382L313 256.6l151-126.3zM11.5 281.2l192 159.4c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6L11.5 232c-15.3 12.8-15.3 36.4 0 49.2zM208 130.3V382L57 256.6l151-126.3z"]
  };
  var faChevronDown = {
    prefix: 'far',
    iconName: 'chevron-down',
    icon: [448, 512, [], "f078", "M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"]
  };
  var faListUl = {
    prefix: 'far',
    iconName: 'list-ul',
    icon: [512, 512, [], "f0ca", "M48 368a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 24H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z"]
  };
  var faNotEqual = {
    prefix: 'far',
    iconName: 'not-equal',
    icon: [384, 512, [], "f53e", "M368 208c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-42.32l55.03-66.81c5.37-7.02 4.04-17.06-2.97-22.43L352.32 35.3c-7.02-5.37-17.06-4.04-22.43 2.97L242.81 144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h174.1l-79.07 96H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h42.32L3.3 434.81c-5.37 7.01-4.04 17.05 2.97 22.43l25.41 19.46c7.02 5.38 17.06 4.04 22.43-2.97L141.19 368H368c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16H193.9l79.07-96H368z"]
  };
  var faSearch = {
    prefix: 'far',
    iconName: 'search',
    icon: [512, 512, [], "f002", "M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"]
  };
  var faSpinner = {
    prefix: 'far',
    iconName: 'spinner',
    icon: [512, 512, [], "f110", "M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"]
  };

  exports.faBackward = faBackward;
  exports.faChevronDown = faChevronDown;
  exports.faListUl = faListUl;
  exports.faNotEqual = faNotEqual;
  exports.faSearch = faSearch;
  exports.faSpinner = faSpinner;

  Object.defineProperty(exports, '__esModule', { value: true });

});
